

























































import { defineComponent } from '@vue/composition-api';
import { useTenantDelete } from '@/composables/store/Management/useTenantDelete';

export default defineComponent({
    setup() {
        const { tenantSystemList, hasTenant, deleteSelected } = useTenantDelete();

        return {
            tenantSystemList,
            hasTenant,
            deleteSelected,
        };
    },
});
