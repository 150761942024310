

































import { defineComponent, onMounted, watch } from '@vue/composition-api';
import ProgressCircularComponent from '@/components/Common/molecules/ProgressCircular.vue';
import CompanyMenuComponent from '@/components/Management/molecules/CompanyMenu.vue';
import TenantDeleteComponent from '@/components/Management/molecules/TenantDeleteAlert.vue';
import DeleteTargetTenantTableComponent from '@/components/Management/molecules/DeleteTargetTenantTable.vue';
import { useTenantDelete } from '@/composables/store/Management/useTenantDelete';
import { useCompanyMenu } from '@/composables/store/Management/useCompanyMenu';
import { useTenantDeleteProgressCircular } from '@/composables/store/Management/useTenantDeleteProgressCircular';

export default defineComponent({
    components: {
        ProgressCircular: ProgressCircularComponent,
        CompanyMenu: CompanyMenuComponent,
        TenantDeleteAlert: TenantDeleteComponent,
        DeleteTargetTenantTable: DeleteTargetTenantTableComponent,
    },
    setup() {
        const {
            fetchTenantList,
            deleteSelected,
            deleteTenantList,
            hasTenant,
            initDeleteSelected,
            initTenantSystemList,
        } = useTenantDelete();
        const { isTenantFetchProgressing, isTenantDeleteProgressing } = useTenantDeleteProgressCircular();
        const { isDefaultSelectCompany, selectedCompany, setSearchCompanyName } = useCompanyMenu();

        onMounted((): void => {
            initTenantSystemList();
        });

        watch(selectedCompany, () => {
            if (isDefaultSelectCompany()) return;

            setSearchCompanyName(selectedCompany.value);
            fetchTenantList(selectedCompany.value);
        });

        const execute = async (): Promise<void> => {
            await deleteTenantList(deleteSelected.value);
            initDeleteSelected();
            fetchTenantList(selectedCompany.value);
        };

        const deletingClass = (): string => (isTenantDeleteProgressing.value ? 'deleting' : '');

        return {
            hasTenant,
            isTenantFetchProgressing,
            isTenantDeleteProgressing,
            selectedCompany,
            deleteSelected,
            execute,
            deletingClass,
        };
    },
});
